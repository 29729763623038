import React from "react";
import Link from "../Navigation/Link";
import { Link as RoutingLink } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./RichLink.scss";

function RichLink({ html = null, image = null, slug, h1 = null }) {
  const path = slug;
  const firstHeading = html
    ? html.match(/<h..*?>([^<>]+)<\/h.>/)[1] || slug
    : slug; //https://regexr.com/5sq11

  const excerptArray = html ? html.match(/(<p[^]+?<\/p>)/gi) : null; //https://regexr.com/5sq2h
  const pTags = excerptArray ? excerptArray.join("") : firstHeading || null;
  const cssModifier = image
    ? ""
    : pTags === null
    ? "--withoutPTags"
    : "--withoutImage";

  const src = getImage(image);
  return (
    <div className={`richLink richLink${cssModifier} container`}>
      <h3 className="richLink__h2">
        <RoutingLink to={path}>{h1 || firstHeading}</RoutingLink>
      </h3>
      {pTags && (
        <RoutingLink to={path} className="richLink__Text">
          <div dangerouslySetInnerHTML={{ __html: pTags }}></div>
        </RoutingLink>
      )}
      {src && (
        <RoutingLink to={path} className="richLink__img">
          <GatsbyImage image={src} alt="" />
        </RoutingLink>
      )}
      <Link to={path} className="richLink__Link">
        {pTags ? "read more" : "see more"}
      </Link>
    </div>
  );
}

export default RichLink;
