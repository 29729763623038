import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import "./index.scss";
import RichLink from "../../components/RichLink/RichLink";
import arrow from "./arrow.svg";
import useTitleContext from "../../context/useTitleContext";
import useMarkdown from "./useMarkdown";
import Seo from "../../components/seo";
import Link from "../../components/Navigation/Link";

function Index() {
  const data = useStaticQuery(graphql`
    query IndexStatic {
      allMarkdownRemark(
        filter: {
          fields: { slug: { regex: "/^/[^/]+/$/" } }
          frontmatter: { template: { ne: "Vita" } }
        }
        sort: { fields: frontmatter___h1 }
      ) {
        edges {
          node {
            fields {
              slug
            }
            excerpt(format: HTML, pruneLength: 200)
            frontmatter {
              h1
              featuredImg {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    transformOptions: { cropFocus: ENTROPY }
                    height: 300
                    width: 400
                  )
                }
              }
            }
          }
        }
      }
    }
  `);
  const edges = data.allMarkdownRemark.edges;
  useTitleContext("");
  const md1 = `
## About
Parallel zu seinen partizipativen Kunstprojekten (gruppe finger) hat Florian Haas kontinuierlich seine serielle Malerei und großformatigen Allegorien weiter entwickelt. In den letzten Jahren hat sich der Künstler verstärkt mit großformatigen Wandarbeiten und Linolschnitten beschäftigt, die durch ihren autobiografischen Hintergrund verbunden sind. In der Gesamtschau ergibt sich eine eigenwillige Form der "Historienmalerei", dessen Grundgerüst sich aus kleinteiligen Erzählungen und Bildergeschichten generiert und dessen Repertoire sich Jahr um Jahr um weitere Kapitel erweitert.
  `;
  const html1 = useMarkdown(md1);

  return (
    <>
      <Seo description={html1} title="Home"></Seo>
      <div className="viewport">
        <Link to="ausstellung">
          <StaticImage
            src="./000COVER.jpg"
            alt="übersicht auf die Arbeit 'Was interessieren mich die Bienen' mit hinweis auf die Onlineaustellung erreichbar unter /ausstellung"
            className="viewport__image"
            layout="fullWidth"
            loading="eager"
          />
          <a href="#about">
            <img src={arrow} className="viewport__arrow" alt="V"></img>
          </a>
        </Link>
      </div>

      <div className="home">
        <div className="container">
          <div className="home__aktuell">
            <h2></h2>
            <Link to="/aktuell">→Aktuelles</Link>
            <br />
            <Link to="/ausstellung">
              →Online Austellung (Was Interessieren mich die Bienen)
            </Link>
          </div>

          <article
            className="home__about"
            dangerouslySetInnerHTML={{ __html: html1 }}
          ></article>
        </div>

        <div className="home__richLinks container">
          {edges.map(entry => {
            const {
              fields: { slug },
              frontmatter: { featuredImg, h1 },
              excerpt,
            } = entry.node;
            return (
              <RichLink
                cssClass={"container home__richLink"}
                key={slug}
                h1={h1}
                image={featuredImg}
                slug={slug}
                html={excerpt}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Index;
